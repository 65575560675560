$pink: #FE205A;
$violet: #43129B;
$dark-blue: #121d47;

/* Common styles for elements */
.accordion-text, .card-text {
    color: $dark-blue;
    text-align: justify;
    font-family: "PT Serif", serif;
    font-style: normal;
    font-weight: 400;
}

.accordion-button, .card-heading {
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    text-align: left;
}

.card-heading {
    color: $pink;
    text-transform: uppercase;
}

li {
    text-align: left;
}

/* Styles for screens under 480px */
@media (min-width: 0px) {
    .accordion-button {
        color: $violet;
        font-size: 0.75rem;
        font-style: normal;
    }

    .accordion-text {
        font-size: 0.65rem;
        line-height: 0.9rem;
    }

    .card-text {
        font-size: 0.7rem;
        line-height: 1rem;
    }
}

/* Styles for 480px-768px screens */
@media (min-width: 480px) {
    .accordion-button {
        color: $violet;
        font-size: 0.9rem;
        font-style: normal;
        line-height: 1.3rem;
    }

    .accordion-text {
        font-size: 0.7rem;
        line-height: 1rem;
    }

    .card-text {
        font-size: 0.8rem;
        line-height: 1.1rem;
    }
}

/* Styles for 768px-992px screens */
@media (min-width: 768px) {
    .accordion-button {
        color: $violet;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.25rem;
    }

    .accordion-text {
        font-size: 0.85rem;
        line-height: 1.15rem;
        word-spacing: 0.25rem;
    }

    .card-text {
        font-size: 0.9rem;
        line-height: 1.3rem;
        word-spacing: 0.3rem;
    }
}

/* Styles for 992px-1280px screens */
@media (min-width: 992px) {
    .accordion-button {
        color: $violet;
        font-size: 1.1rem;
        font-style: normal;
        line-height: 1.5rem;
    }

    .accordion-text {
        font-size: 0.95rem;
        line-height: 1.3rem;
        word-spacing: 0.15rem;
    }

    .card-text {
        font-size: 1rem;
        line-height: 1.4rem;
        word-spacing: 0.2rem;
    }
}

/* Styles for 1280px+ screens */
@media (min-width: 1280px) {
    .accordion-button {
        color: $violet;
        font-size: 1.2rem;
        font-style: normal;
        line-height: 1.5rem;
    }

    .accordion-text {
        font-size: 1.1rem;
        line-height: 1.45;
        word-spacing: 0.4rem;
    }

    .card-text {
        font-size: 1.2rem;
        line-height: 1.8rem;
        word-spacing: 0.4rem;
    }
}