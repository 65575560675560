$pink: #FE205A;
$violet: #43129B;
$dark-blue: #121d47;
$font-sans: "PT Sans", sans-serif;
$font-serif: "PT Serif", serif;
$font-size-base: 0.75rem;
$font-size-small: 0.8rem;
$font-size-medium: 0.9rem;
$font-size-large: 1rem;
$font-size-xlarge: 1.2rem;

/* Common styles for elements */
main h1 {
  font-family: $font-sans;
  font-style: normal;
  font-weight: 700;
}

.home-article,
h2 {
  font-family: $font-serif;
  font-style: normal;
  font-weight: 400;
}

main h1 {
  color: $pink;
  text-transform: uppercase;
  text-align: left;
}

.home-article {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: $dark-blue;
  text-align: justify;
}

h2 {
  color: $violet;
  text-align: center;
}

.home-article li {
  text-align: justify;
}

.home-article ol {
  counter-reset: list;
  padding: 0;
}

.home-article ol > li {
  list-style: none;
  text-align: justify;
}

.home-article ol > li:before {
  content: counter(list) ") ";
  counter-increment: list;
}

/* Styles for screens under 480px (Base) */
@media (max-width: 479px) {
  main h1,
  .home-article,
  .home-article li,
  h2 {
    font-size: $font-size-base;
  }

  main h1 {
    line-height: 1.6rem;
  }

  .home-article, h2, .home-article li {
    line-height: 1.05rem;
  }

  .home-article p, .home-article li {
    padding-bottom: 0.4rem;
  }
}

/* Styles for 480px-768px screens (Small) */
@media (min-width: 480px) and (max-width: 767px) {
  main h1,
  .home-article,
  .home-article li,
  h2 {
    font-size: $font-size-small;
  }

  main h1 {
    line-height: 1.8rem;
  }

  .home-article, h2, .home-article li {
    line-height: 1.05rem;
  }

  .home-article p, .home-article li {
    padding-bottom: 0.4rem;
  }
}

/* Styles for 768px-992px screens (Medium) */
@media (min-width: 768px) and (max-width: 991px) {
  main h1,
  .home-article,
  .home-article li,
  h2 {
    font-size: $font-size-medium;
  }

  main h1 {
    line-height: 2rem;
  }

  .home-article, .home-article li {
    line-height: 1.3rem;
    word-spacing: 0.2rem;
  }

  .home-article p, .home-article li {
    padding-bottom: 0.5rem;
  }

  h2 {
    line-height: 1.2rem;
  }
}

/* Styles for 992px-1280px screens (Large) */
@media (min-width: 992px) and (max-width: 1279px) {
  main h1,
  .home-article,
  .home-article li,
  h2 {
    font-size: $font-size-large;
  }

  main h1 {
    line-height: 2rem;
  }

  .home-article, .home-article li {
    line-height: 1.5rem;
    word-spacing: 0.3rem;
  }

  .home-article p, .home-article li {
    padding-bottom: 0.5rem;
  }

  h2 {
    line-height: 1.65rem;
  }
}

/* Styles for 1280px+ screens (XLarge) */
@media (min-width: 1280px) {
  main h1,
  .home-article,
  .home-article li,
  h2 {
    font-size: $font-size-xlarge;
  }

  main h1 {
    line-height: 3rem;
  }

  .home-article, .home-article li {
    line-height: 1.8rem;
    word-spacing: 0.4rem;
  }

  .home-article p, .home-article li {
    padding-bottom: 0.5rem;
  }

  h2 {
    line-height: 1.65rem;
  }
}