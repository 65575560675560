$pink: #fe205a;
$violet: #43129b;
$dark-blue: #121d47;
$light-gray: #f0f6f1;
$font-sans: 'PT Sans', sans-serif;
$font-serif: 'PT Serif', serif;

.contacts {
  height: 65vh;
}

.contact h2 {
  color: $violet;
  font-family: $font-sans;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.contact p {
  color: $dark-blue;
  font-family: $font-serif;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.staff-contacts-container {
  display: flex;
  justify-content: space-between;
}

/* Styles for screens under 480px */
@media (min-width: 0px) {
  .staff-contacts-container {
    flex-direction: column;
    padding-bottom: 0.25rem;
  }

  .contact {
    padding-bottom: 0.25rem;
  }

  .contact h1 {
    font-size: 0.75rem;
    line-height: 1.6rem;
  }

  .contact h2,
  .contact p {
    font-size: 0.75rem;
    line-height: 1.05rem;
  }
}

/* Styles for 480px-768px screens */
@media (min-width: 480px) {
  .contact h1 {
    font-size: 0.8rem;
    line-height: 1.8rem;
  }

  .contact h2,
  .contact p {
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
}

/* Styles for 768px-992px screens */
@media (min-width: 768px) {
  .staff-contacts-container {
    padding-bottom: 0.25rem;
  }

  .contact {
    padding-bottom: 0.5rem;
  }

  .contact h1 {
    font-size: 0.9rem;
    line-height: 2rem;
  }

  .contact h2 {
    padding-bottom: 0.25rem;
  }

  .contact h2,
  .contact p {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
}

/* Styles for 992px-1280px screens */
@media (min-width: 992px) {
  .contact {
    padding-bottom: 0.75rem;
  }

  .contact h1 {
    font-size: 1rem;
    line-height: 2rem;
  }

  .contact h2 {
    padding-bottom: 0.3rem;
  }

  .contact h2,
  .contact p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

/* Styles for 1280px+ screens */
@media (min-width: 1280px) {
  .contact {
    padding-bottom: 1rem;
  }

  .contact h1 {
    font-size: 1.2rem;
    line-height: 3rem;
  }

  .contact h2 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .contact p {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}
