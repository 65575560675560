$pink: #FE205A;
$violet: #43129B;
$dark-blue: #121d47;
$light-gray: #F0F6F1;
$font-size-small: 0.9rem;
$font-size-medium: 1rem;
$font-size-large: 1.2rem;

/* Common styles for elements */

/* Text-colors */
.staff-name-tab {
  color: $pink;
}

.staff-article h2 {
  color: $violet;
}

nav a, .staff-member, .staff-article p {
  color: $dark-blue;
}

/* Fonts */
.staff-article h2, .staff-name-tab, nav a {
  font-family: "PT Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

.staff-member, .staff-article p, .staff-article li {
  font-family: "PT Serif", serif;
  font-style: normal;
  font-weight: 400;
}

/* Other styles */
.staff-name-tab {
  text-transform: uppercase;
}

.staff-article p, .staff-article li {
  text-align: justify;
}

/* Styles for screens under 480px */
@media (min-width: 0px) {

  .staff-nav a {
    font-size: $font-size-small;
    line-height: 1rem;
    align-content: flex-start;
    flex-wrap: wrap;
    max-width: fit-content;
  }

  .staff-article h2 {
    font-size: $font-size-small;
    line-height: 1;
    padding: 0.4rem 0;
  }

  .staff-article p, .staff-article li {
    font-size: $font-size-small;
    line-height: 1.3rem;
    padding-bottom: 0.4rem;
    word-spacing: 0.1rem;
  }

  .staff-article ol {
    padding-top: 0.4rem;
  }
}

/* Styles for 480px-768px screens */
@media (min-width: 480px) {

  .staff-nav a {
    font-size: $font-size-small;
    line-height: 1rem;
    align-content: flex-start;
    flex-wrap: wrap;
    max-width: fit-content;
  }

  .staff-article h2 {
    font-size: $font-size-small;
    line-height: 1;
    padding: 0.5rem 0;
  }

  .staff-article p, .staff-article li {
    font-size: $font-size-small;
    line-height: 1.4rem;
    padding-bottom: 0.5rem;
    word-spacing: 0.2rem;
  }

  .staff-article ol {
    padding-top: 0.5rem;
  }
}

/* Styles for 768px-992px screens */
@media (min-width: 768px) {

  .staff-nav {
    margin: auto;
    padding-bottom: 0.5rem;
  }

  .staff-nav a {
    font-size: $font-size-medium;
    line-height: 1rem;
  }

  .staff-article h2 {
    font-size: $font-size-medium;
    line-height: 1.5rem;
    padding: 0.5rem 0;
  }

  .staff-article p, .staff-article li {
    font-size: $font-size-medium;
    line-height: 1.3rem;
    padding-bottom: 0.5rem;
    word-spacing: 0.3rem;
  }

  .staff-article ol {
    padding-top: 0.5rem;
  }
}

/* Styles for 992px-1280px screens */
@media (min-width: 992px) {

  .staff-nav {
    margin: auto;
    padding-bottom: 0.625rem;
  }

  .staff-nav a {
    font-size: $font-size-medium;
    line-height: 1rem;
  }

  .staff-article h2 {
    font-size: $font-size-medium;
    line-height: 1.25rem;
  }

  .staff-article p, .staff-article li {
    font-size: $font-size-medium;
    line-height: 1.5rem;
    padding-bottom: 0.5rem;
    word-spacing: 0.4rem;
  }

  .staff-article ol {
    padding-top: 0.5rem;
  }
}

/* Styles for 1280px+ screens */
@media (min-width: 1280px) {

    .staff-nav {
      width: 100%;
      margin: auto;
      padding-bottom: 0.75rem;
    }

    .staff-nav a {
      font-size: 1.1rem;
      line-height: 1.1rem;
    }

    .staff-article h2 {
      font-size: $font-size-large;
      line-height: 1.8rem;
    }

    .staff-article p, .staff-article li {
      font-size: $font-size-large;
      line-height: 1.8rem;
      padding-bottom: 0.625rem;
      word-spacing: 0.4rem;
    }

    .staff-article ol {
      padding-top: 0.625rem;
    }
}