/* Styles for screens under 480px */
@media (min-width: 0px) {
  .full-screen-header,
  .full-screen-menu {
    display: none;
  }

  .mobile-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f6f1;
    padding: 1rem 0 0 0;
  }

  .mobile-menu {
    display: flex;
  }

  .menu-list a {
    color: #121d47;
    font-family: PT Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.75rem;
    text-transform: uppercase;
  }

  .menu-list a:hover {
    background-color: #c4eaa5;
  }

  .header-container-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 1.625rem;
  }

  .header-container-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 0.2rem;
  }

  .logo2 {
    width: 1.625rem;
  }

  .mobile-header h1 {
    color: #fe205a;
    text-align: center;
    font-family: PT Sans;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2rem;
  }

  .mobile-header h2 {
    color: #43129b;
    text-align: center;
    font-family: PT Sans;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.75rem;
    padding: 0.2rem 0;
  }

  .lab-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mobile-header h3 {
    color: #121d47;
    font-family: PT Sans;
    font-size: 0.65rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.6rem;
  }

  .lab-info #lab {
    text-align: right;
  }

  .lab-info #group {
    text-align: left;
  }
}

/* Styles for 480px-768px screens */
@media (min-width: 480px) {
  .mobile-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f6f1;
    padding: 1.25rem 0 0 0;
  }

  .full-screen-header,
  .full-screen-menu {
    display: none;
  }

  .mobile-menu {
    display: flex;
  }

  .menu a {
    color: #121d47;
    font-family: PT Sans;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.85rem;
    text-transform: uppercase;
  }

  .menu .experiment-link a {
    color: #fe205a;
  }

  .menu a:hover {
    background-color: #c4eaa5;
  }

  .header-container-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 1.625rem;
  }

  .header-container-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-top: 0.3rem;
  }

  .logo2 {
    width: 1.625rem;
  }

  .mobile-header h1 {
    color: #fe205a;
    text-align: center;
    font-family: PT Sans;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4rem;
  }

  .mobile-header h2 {
    color: #43129b;
    text-align: center;
    font-family: PT Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.8rem;
    padding: 0.3rem 0;
  }

  .lab-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mobile-header h3 {
    color: #121d47;
    font-family: PT Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.7rem;
  }

  .lab-info #lab {
    text-align: right;
  }

  .lab-info #group {
    text-align: left;
  }
}

/* Styles for 768px-992px screens */
@media (min-width: 768px) {
  /* Hide the mobile menu on larger screens */
  .mobile-header,
  .mobile-menu {
    display: none;
  }

  /* Show the full-screen menu on larger screens */
  .full-screen-menu {
    display: block;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: transform 0.3s ease-in-out;
    background-color: #f0f6f1;
    transform: translateY(0);
  }

  .header.hidden {
    transform: translateY(-10rem);
  }

  .full-screen-header {
    display: flex;
    justify-content: space-between;
    width: 80vw;
    margin: 0 10vw;
    padding-top: 0.315rem;
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .left-content h1 {
    margin-bottom: 0;
    color: #fe205a;
    font-family: PT Sans;
    font-size: 1.45rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.45rem;
    text-align: left;
    padding-bottom: 0.7rem;
  }

  .logo1 {
    display: block;
    width: 13rem;
    height: auto;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .right-content h2 {
    padding-top: 0.2rem;
    color: #43129b;
    text-align: right;
    font-family: PT Sans;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.85rem;
  }

  .lab-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .right-content h3 {
    padding-top: 0.3rem;
    color: #121d47;
    text-align: right;
    font-family: PT Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.75rem;
  }

  /* Styles for the navigation menu */
  nav {
    display: flex;
    width: 80vw;
    margin: 0 10vw;
    padding-bottom: 0.4rem;
  }

  .menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    list-style-type: none;
    padding: 0;
    width: 80vw;
  }

  .menu li {
    margin-right: 0.625rem;
  }

  .menu li:last-child {
    margin-right: 0;
  }

  .menu a {
    color: #121d47;
    font-family: PT Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .menu .experiment-link a {
    color: #fe205a;
  }

  .menu a:hover {
    background-color: #c4eaa5;
  }
}

/* Styles for 992px-1280px screens */
@media (min-width: 992px) {
  /* Hide the mobile menu on larger screens */
  .mobile-header,
  .mobile-menu {
    display: none;
  }

  /* Show the full-screen menu on larger screens */
  .full-screen-menu {
    display: block;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: transform 0.3s ease-in-out;
    background-color: #f0f6f1;
    transform: translateY(0);
  }

  .header.hidden {
    transform: translateY(-10rem);
  }

  .full-screen-header {
    display: flex;
    justify-content: space-between;
    width: 64vw;
    margin: 0 18vw;
    padding-top: 0.315rem;
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .left-content h1 {
    margin-bottom: 0;
    color: #fe205a;
    font-family: PT Sans;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.6rem;
    text-align: left;
    padding-bottom: 0.75rem;
  }

  .logo1 {
    display: block;
    width: 14.5rem;
    height: auto;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .right-content h2 {
    padding-top: 0.3rem;
    color: #43129b;
    text-align: right;
    font-family: PT Sans;
    font-size: 0.95rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.95rem;
  }

  .lab-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .right-content h3 {
    padding-top: 0.35rem;
    color: #121d47;
    text-align: right;
    font-family: PT Sans;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.75rem;
  }

  /* Styles for the navigation menu */
  nav {
    display: flex;
    width: 64vw;
    margin: 0 18vw;
    padding-bottom: 0.5rem;
  }

  .menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: space-between;
    align-content: center;
    list-style-type: none;
    padding: 0;
    width: 64vw;
    max-height: 1.6875rem;
  }

  .menu li {
    margin-right: 0.625rem;
  }

  .menu li:last-child {
    margin-right: 0;
  }

  .menu a {
    color: #121d47;
    font-family: PT Sans;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .menu .experiment-link a {
    color: #fe205a;
  }

  .menu a:hover {
    background-color: #c4eaa5;
  }
}

/* Styles for 1280px+ screens */
@media (min-width: 1280px) {
  /* Hide the mobile menu on larger screens */
  .mobile-menu {
    display: none;
  }

  /* Show the full-screen menu on larger screens */
  .full-screen-menu {
    display: block;
  }

  .mobile-header {
    display: none;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: transform 0.3s ease-in-out;
    background-color: #f0f6f1;
    transform: translateY(0);
  }

  .header.hidden {
    transform: translateY(-10rem);
  }

  .full-screen-header {
    display: flex;
    justify-content: space-between;
    width: 64vw;
    margin: 0 18vw;
    padding-top: 0.315rem;
  }

  .left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .left-content h1 {
    margin-bottom: 0;
    color: #fe205a;
    font-family: PT Sans;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.2rem;
    text-align: left;
    padding-bottom: 0.8rem;
  }

  .logo1 {
    display: block;
    width: 19rem;
    height: auto;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .right-content h2 {
    padding-top: 0.5rem;
    color: #43129b;
    text-align: right;
    font-family: PT Sans;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2rem;
  }

  .lab-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .right-content h3 {
    padding-top: 0.5rem;
    color: #121d47;
    text-align: right;
    font-family: PT Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
  }

  /* Styles for the navigation menu */
  nav {
    display: flex;
    width: 64vw;
    margin: 0 18vw;
    padding-bottom: 0.625rem;
  }

  .menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: space-between;
    align-content: center;
    list-style-type: none;
    padding: 0;
    width: 64vw;
    max-height: 1.6875rem;
  }

  .menu li {
    margin-right: 0.625rem;
  }

  .menu li:last-child {
    margin-right: 0;
  }

  .menu a {
    color: #121d47;
    font-family: PT Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .menu .experiment-link a {
    color: #fe205a;
  }

  .menu a:hover {
    background-color: #c4eaa5;
  }
}
