$pink: #fe205a;
$violet: #43129b;
$dark-blue: #121d47;
$light-green: #c4eaa5;
$font-size-small: 0.9rem;
$font-size-medium: 1rem;
$font-size-large: 1.2rem;

/* Common styles for elements */

div .scroll-button {
  background-color: $light-green;
  color: $dark-blue;
}

.research-accordion-button {
  color: $violet;
}

.school-tab {
  color: $pink;
}

#research .research-card-heading {
  color: $violet;
}

main .research-dir-tab,
.school-tab,
#research .research-card-heading,
.research-accordion-button,
div .scroll-button {
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

main .research-dir-tab {
  color: $dark-blue;
}

.school-tab {
  appearance: none;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
}

.research-card-text,
.research-accordion-text {
  color: $dark-blue;
  font-family: 'PT Serif', serif;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
}

.research-card-text li {
  padding: 0;
}

div .scroll-button {
  text-align: center;
  white-space: normal;
  user-select: none;
}

.research-list li {
  text-align: justify;
}

/* Styles for under 480px screens */
@media (min-width: 0px) {
  main .research-dir-tab {
    font-size: $font-size-small;
    line-height: 1rem;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
  }

  .school-tab {
    min-width: 2rem;
    height: 1.6rem;
  }

  #research {
    padding-top: 0.25rem;
  }

  #research .research-card-heading {
    font-size: $font-size-small;
    line-height: 1.3rem;
    padding-right: 0.5rem;
  }

  .research-card-text,
  .research-card-text li {
    font-size: $font-size-small;
    line-height: 1.3rem;
    word-spacing: 0.1rem;
  }

  .research-card-text {
    padding-bottom: 0.4rem;
  }

  .research-accordion-text {
    font-size: 0.85rem;
    line-height: 1.2rem;
    padding-bottom: 0.3rem;
    word-spacing: 0.1rem;
  }

  .research-accordion-button,
  .under_development p {
    font-size: $font-size-small;
    line-height: 1.3;
  }

  div .scroll-button {
    height: 2.5rem;
    line-height: 0.9;
    padding: 0 0.5rem;
  }
}

/* Styles for 480px-768px screens */
@media (min-width: 480px) {
  main .research-dir-tab {
    font-size: $font-size-small;
    line-height: 1rem;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: left;
  }

  .school-tab {
    min-width: 2rem;
    height: 1.6rem;
  }

  #research {
    padding-top: 0.3rem;
  }

  #research .research-card-heading {
    font-size: $font-size-small;
    line-height: 1rem;
    padding-right: 0.5rem;
  }

  .research-card-text,
  .research-card-text li {
    font-size: $font-size-small;
    line-height: 1.4rem;
    word-spacing: 0.2rem;
  }

  .research-card-text {
    padding-bottom: 0.5rem;
  }

  .research-accordion-text {
    font-size: 0.85rem;
    line-height: 1.25rem;
    padding-bottom: 0.4rem;
    word-spacing: 0.2rem;
  }

  .research-accordion-button,
  .under_development p {
    font-size: $font-size-small;
    line-height: 1;
  }

  div .scroll-button {
    height: 2.5rem;
    line-height: 0.9;
    padding: 0 0.5rem;
  }
}

/* Styles for 768px-992px screens */
@media (min-width: 768px) {
  main .research-dir-tab {
    font-size: $font-size-medium;
    line-height: 1rem;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: left;
  }

  .school-tab {
    min-width: 2rem;
    height: 1.8rem;
  }

  #research {
    padding-top: 0.4rem;
  }

  #research .research-card-heading {
    font-size: $font-size-medium;
    line-height: 1.5rem;
    padding-right: 1rem;
  }

  .research-card-text,
  .research-card-text li {
    font-size: $font-size-medium;
    line-height: 1.5rem;
    word-spacing: 0.3rem;
  }

  .research-card-text {
    padding-bottom: 0.5rem;
  }

  .research-accordion-text {
    font-size: 0.95rem;
    line-height: 1.35rem;
    padding-bottom: 0.4rem;
    word-spacing: 0.25rem;
  }

  .research-accordion-button,
  .under_development p {
    font-size: $font-size-medium;
    line-height: 1;
  }

  div .scroll-button {
    height: 3rem;
    line-height: 1;
    padding: 0 0.5rem;
  }
}

/* Styles for 992px-1280px screens */
@media (min-width: 992px) {
  main .research-dir-tab {
    font-size: $font-size-medium;
    line-height: 1;
    align-content: flex-start;
    flex-wrap: wrap;
    text-align: left;
  }

  .school-tab {
    min-width: 2rem;
    height: 2rem;
  }

  #research {
    padding-top: 0.5rem;
  }

  #research .research-card-heading {
    font-size: $font-size-medium;
    line-height: 1.25rem;
    padding-right: 1rem;
  }

  .research-card-text,
  .research-card-text li {
    font-size: $font-size-medium;
    line-height: 1.5rem;
    word-spacing: 0.4rem;
  }

  .research-card-text {
    padding-bottom: 0.5rem;
  }

  .research-accordion-text {
    font-size: 0.95rem;
    line-height: 1.4rem;
    padding-bottom: 0.4rem;
    word-spacing: 0.3rem;
  }

  .research-accordion-button,
  .under_development p {
    font-size: $font-size-medium;
    line-height: 1.2rem;
  }

  div .scroll-button {
    height: 3rem;
    line-height: 1;
    padding: 0 0.5rem;
  }
}

/* Styles for 1280px+ screens */
@media (min-width: 1280px) {
  main .research-dir-tab {
    font-size: 1.1rem;
    line-height: 1.2rem;
    align-content: flex-start;
    flex-wrap: wrap;
    text-align: left;
  }

  .school-tab {
    min-width: 2.5rem;
    height: 2.5rem;
  }

  #research {
    padding-top: 0.5rem;
  }

  #research .research-card-heading {
    font-size: $font-size-large;
    line-height: 1.2rem;
    padding-bottom: 0.5rem;
  }

  .research-card-text,
  .research-card-text li {
    font-size: $font-size-large;
    line-height: 1.8rem;
    word-spacing: 0.4rem;
  }

  .research-card-text {
    padding-bottom: 0.875rem;
  }

  .research-accordion-text {
    font-size: 1.1rem;
    line-height: 1.5rem;
    padding-bottom: 0.625rem;
    word-spacing: 0.4rem;
  }

  .research-accordion-button,
  .under_development p {
    font-size: $font-size-large;
    line-height: 1.5rem;
  }

  div .scroll-button {
    height: 3rem;
    line-height: 1.2rem;
    text-align: center;
  }
}
