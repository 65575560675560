/* Common styles for elements */
* {
  background-color: #f0f6f1;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
}

ol,
ul {
  list-style-position: inside;
}

main h1 {
  color: #fe205a;
  text-transform: uppercase;
  text-align: left;
  font-family: 'PT Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}

/* Styles for screens under 480px (Base) */
@media (max-width: 479px) {
  main {
    width: 90vw;
    margin: 0 5vw;
  }

  main h1 {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }
}

/* Styles for 480px-768px screens (Small) */
@media (min-width: 480px) and (max-width: 767px) {
  main {
    width: 80vw;
    margin: 0 10vw;
  }

  main h1 {
    font-size: 0.9rem;
    line-height: 1.8rem;
  }
}

/* Styles for 768px-992px screens (Medium) */
@media (min-width: 768px) and (max-width: 991px) {
  main {
    width: 80vw;
    margin: 6.85rem 10vw 0 10vw;
  }

  main h1 {
    font-size: 1rem;
    line-height: 2rem;
  }
}

/* Styles for 992px-1280px screens (Large) */
@media (min-width: 992px) and (max-width: 1279px) {
  main {
    width: 64vw;
    margin: 7.85rem 18vw 0 18vw;
  }

  main h1 {
    font-size: 1rem;
    line-height: 2rem;
  }
}

/* Styles for 1280px+ screens (XLarge) */
@media (min-width: 1280px) {
  main {
    width: 64vw;
    margin: 9.9rem 18vw 0 18vw;
  }

  main h1 {
    font-size: 1.2rem;
    line-height: 3rem;
  }
}
